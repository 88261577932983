import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { version } from 'src/version';
import { AppConfigService } from './app-config.service';
const appInitializerFn = (appConfig) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};
const ɵ0 = appInitializerFn;
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, 'translation/' + version + '-signup-', '.json');
}
export class AppModule {
}
export { ɵ0 };
