import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public title = '';
  public message = '';
  public alertVisible = false;

  public alert(title: string, message: string): void {
    this.title = title;
    this.message = message;
    this.alertVisible = true;
  }
}
