import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private modalService: ModalService, private translateService: TranslateService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught: Observable<any>) => {
        if (err instanceof HttpErrorResponse) {
          let message: string = err.statusText;
          if (err.url) {
            message += ' - ' + err.url;
          }
          switch (err.status) {
            case 0:
            case -1:
              this.modalService.alert('', 'Error.NetworkError');
              this.translateService.use('da').subscribe(() => {});
              break;
            case 400:
              break;
            case 401:
              break;
            case 404:
              break;
            default:
              Raven.captureException(new Error(message), { extra: err });
              break;
          }
        }

        return throwError(err);
      })
    );
  }
}
