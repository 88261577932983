import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { CompanyCreationRequest, ICompany, ICvrCompany, IIdentityCheckResponse, ISignupReferral } from './model';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private signupReferralsSubject!: BehaviorSubject<ISignupReferral[]>;

  public get signupReferrals() {
    if (!this.signupReferralsSubject) {
      this.signupReferralsSubject = new BehaviorSubject<ISignupReferral[]>([]);
      this.getSignupReferrals().subscribe((data: ISignupReferral[]) => this.signupReferralsSubject.next(data));
    }

    return this.signupReferralsSubject.asObservable();
  }

  private languageValue = '';
  public get language() {
    return this.languageValue;
  }
  public set language(value: string) {
    if (this.languageValue !== value) {
      this.languageValue = value;
      this.translate.use(value).subscribe(() => {});
    }
  }

  private knowGratisalFromValue = '';
  public get knowGratisalFrom() {
    return this.knowGratisalFromValue;
  }
  public set knowGratisalFrom(value: string) {
    this.knowGratisalFromValue = value;
    this.selectedReferral = this.signupReferralsSubject.value.find((e) => e.Key === value);
  }

  public selectedReferral: ISignupReferral | undefined;

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private translate: TranslateService
  ) {}

  public validatePassword(password: string): Observable<boolean> {
    const path: string = this.configService.config.apiUrl + '/api/auth/validatepw';
    return this.httpClient.post<boolean>(path, { password });
  }

  /**
   * Checks that a given identity (CPR) number is well-formed, and whether it represents an existing Gratisal user.
   * Also gives the associated date of birth, and whether a warning should be raised for a failed "modulo 11 check".
   */
  public checkIdentityNumber(identityNumber: string): Observable<IIdentityCheckResponse> {
    let path: string = this.configService.config.apiUrl + '/api/users/identity/{identityNumber}';
    path = path.replace('{identityNumber}', identityNumber.toString());
    return this.httpClient.get<IIdentityCheckResponse>(path);
  }

  /**
   * Submits a request to sign up as a new customer - creating an account, a company and a user. This method may be
   * called anonymously.
   * @param An object containing information about the company to create. Note that AccountId and RequestingUserId
   *        must always be null when using this route. To create a new company when logged on, call POST to
   *        api/account/companies instead.
   */
  public signup(request: CompanyCreationRequest): Observable<ICompany> {
    const path: string = this.configService.config.apiUrl + '/api/companies/signup';
    return this.httpClient.post<ICompany>(path, request);
  }

  /**
   * Gets information about a company by CVR number. Powered by www.cvrapi.dk.
   */
  public getCompanyByCvr(cvrNumber: string): Observable<ICvrCompany> {
    let path: string = this.configService.config.apiUrl + '/api/companies/cvr/{cvrNumber}';
    path = path.replace('{cvrNumber}', cvrNumber.toString());
    return this.httpClient.get<ICvrCompany>(path);
  }

  private getSignupReferrals(): Observable<ISignupReferral[]> {
    const path: string = this.configService.config.apiUrl + '/api/staticdata/signupreferral';
    return this.httpClient.get<ISignupReferral[]>(path);
  }
}
