import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { ModalService } from '../modal.service';
import { CompanyCreationRequest, ICompany, ICvrCompany, IIdentityCheckResponse } from '../model';
import { SignupService } from '../signup.service';

declare var gtag_report_conversion: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: []
})
export class SignupComponent implements OnInit {
  @Input() public language = 'en';

  public get newAccountNameHint(): string {
    if (this.value.AccountTypeId) {
      switch (this.value.AccountTypeId) {
        case 1:
          return 'SignUpForm.NameOfAccountantFirm';
        case 2:
          return 'SignUpForm.NameOfPayrollAgency';
        case 3:
          return 'SignUpForm.NameOfCorporation';
        default:
          break;
      }
    }
    return '';
  }

  public value = new CompanyCreationRequest();
  public newCompanyId = 0;

  public isBusy = false;
  public submitting = false;

  public alreadyHaveAccountDialogVisible = false;
  public passwordExplanationDialogVisible = false;
  public representInfoDialogVisible = false;
  public startupOfferDialogVisible = false;
  public unknownCompanyDialogVisible = false;
  public invalidCprDialogVisible = false;
  public checkboxValidationDialogVisible = false;
  public unexpectedErrorDialogVisible = false;
  public signupErrorDialogVisible = false;
  public signupErrorMessage = '';
  public signupConfirmationDialogVisible = false;
  public NoteForSignupDialogVisible = false;

  public cvrExists = false;
  public vatRegistrationNumberValid = true;
  public companyNameValid = true;
  public attpersonValid = true;
  public emailValid = true;
  public noCPRNumber = false;
  public validCPR = true;
  public hidePassword = false;
  public showPassword = false;
  public passwordValid = true;
  public termsChecked = false;
  public otherReferral = '';

  constructor(
    public configService: AppConfigService,
    public service: SignupService,
    public modalService: ModalService,
    private route: ActivatedRoute,
    private titleservice: Title,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.translate.use('da').subscribe(() => {
      // default cache and download lang 'da' json
      this.route.paramMap.subscribe((param) => {
        const language = param.get('language');
        this.service.language = language || 'da';
        this.translate.use(this.service.language).subscribe(() => {
          this.value.SubscribeToNewsLetter = true;
          this.translateText();
        });
      });
    });
  }

  private translateText(): void {
    this.translate.get(['Title']).subscribe((translations: { [key: string]: string }) => {
      this.titleservice.setTitle(translations.Title);
    });
  }

  public closealldialog(): void {
    if (this.signupConfirmationDialogVisible) {
      this.onShowLoginTab();
      return;
    }
    this.passwordExplanationDialogVisible = false;
    this.unknownCompanyDialogVisible = false;
    this.invalidCprDialogVisible = false;
    this.signupConfirmationDialogVisible = false;
    this.checkboxValidationDialogVisible = false;
    this.unexpectedErrorDialogVisible = false;
    this.signupErrorDialogVisible = false;
    this.startupOfferDialogVisible = false;
    this.alreadyHaveAccountDialogVisible = false;
    this.representInfoDialogVisible = false;
    this.NoteForSignupDialogVisible = false;
    this.modalService.alertVisible = false;
  }

  public onmodalclick(event: any): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public onNoteForSignupDialogVisible(): void {
    this.NoteForSignupDialogVisible = true;
    setTimeout(() => {
      const elementCall: any = document.querySelectorAll('.NoteForSignupMessageCallLink');
      elementCall.forEach((item: any) => {
        item.addEventListener('click', () => {
          window.location.href = item.href;
        });
      });
    });
  }

  public onShowLoginTab(): void {
    window.location.href = '/login';
  }

  public checkCVR() {
    if (this.value.VatRegistrationNumber.length === 0) {
      this.vatRegistrationNumberValid = false;
      return;
    }

    this.submitting = true;
    this.service
      .getCompanyByCvr(this.value.VatRegistrationNumber)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        (data: ICvrCompany) => {
          this.value.CompanyName = data.Name || '';
          if (this.value.CompanyName) {
            this.companyNameValid = true;
            this.emailValid = true;
          }

          if (data.Owners && data.Owners.length > 0) {
            this.value.ResponsibleUserFullName = data.Owners[0].Name || '';
          }

          if (this.value.ResponsibleUserFullName) {
            this.attpersonValid = true;
          }

          this.cvrExists = data.ExistsInGratisal;
          this.value.PhoneNumber = data.Phone || '';
        },
        (error: any) => {
          if (error.status === 404) {
            this.unknownCompanyDialogVisible = true;
          }
        }
      );
  }

  public checkCPR() {
    this.validCPR = this.value.ResponsibleUserIdentityNumber !== '';
    this.submitting = true;
    let existsInGratisal = false;
    this.service
      .checkIdentityNumber(this.value.ResponsibleUserIdentityNumber)
      .pipe(
        finalize(() => {
          this.submitting = false;
          this.hidePassword = existsInGratisal;
        })
      )
      .subscribe(
        (data: IIdentityCheckResponse) => {
          existsInGratisal = data.ExistsInGratisal;
          this.validCPR = data.IsWellFormed;
        },
        (error: any) => {
          if (error.status === 404) {
            return;
          }

          this.invalidCprDialogVisible = true;
        }
      );
  }

  public checkPassword() {
    if (this.value.NewUserPassword.length === 0) {
      this.passwordValid = true;
      return;
    }

    this.submitting = true;
    this.service
      .validatePassword(this.value.NewUserPassword)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe((data: boolean) => (this.passwordValid = data));
  }

  public signup() {
    if (!this.checkRequiredFields()) {
      return;
    }

    this.value.KnowsGratisalFrom =
      this.service.selectedReferral &&
      this.service.selectedReferral.AdditionalInfoRelevant &&
      this.otherReferral &&
      this.otherReferral.length > 0
        ? this.otherReferral
        : this.service.knowGratisalFrom;
    this.value.LanguageId = this.language === 'da' ? 1 : 2;

    this.submitting = true;
    this.isBusy = true;
    this.service
      .signup(this.value)
      .pipe(
        finalize(() => {
          this.submitting = false;
          this.isBusy = false;
        })
      )
      .subscribe(
        (data: ICompany) => {
          this.newCompanyId = data.Id;
          gtag_report_conversion();
          this.signupConfirmationDialogVisible = true;
        },
        (error: any) => {
          if (error.status === 400) {
            this.signupErrorMessage = error.error.Message;
            this.signupErrorDialogVisible = true;
          } else {
            this.unexpectedErrorDialogVisible = false;
          }
        }
      );
  }

  private checkRequiredFields() {
    let result = true;

    this.vatRegistrationNumberValid = true;
    if (this.value.VatRegistrationNumber.trim() === '') {
      this.vatRegistrationNumberValid = false;
      result = false;
    }

    this.companyNameValid = true;
    if (this.value.CompanyName.trim() === '') {
      this.companyNameValid = false;
      result = false;
    }

    this.attpersonValid = true;
    if (this.value.ResponsibleUserFullName.trim() === '') {
      this.attpersonValid = false;
      result = false;
    }

    this.emailValid = true;
    if (this.value.ResponsibleUserEmail.trim() === '') {
      this.emailValid = false;
      result = false;
    }

    if (!this.termsChecked) {
      this.checkboxValidationDialogVisible = true;
      result = false;
    }

    return result;
  }
}
