<div class="Signup-content-feature">
  <div class="Signup-features">
    <div class="Signup-featuresTitle">{{ 'SignUpForm.BasicFreeSalary' | translate }}</div>
    <ul class="Signup-featuresContent">
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.FullPayrollManagement' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.EmployeePortalPayslip' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.IntuitiveAndInteractive' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.MobileApps' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.UnlimitedEmployeesAndPayrollLines' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.EmploymentTemplatesFast' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.AutomaticTaxcard' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.PensionPlansAndVacation' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.ReportsAndDataExport' | translate }}</div>
      </li>
      <li>
        <div><img src="checkbox.png" width="16" height="16" /></div>
        <div>{{ 'SignUpForm.EasyAndCustomizable' | translate }}</div>
      </li>
    </ul>

    <div class="Signup-featuresFooter">
      <div>
        <a class="Button--primary u-block" [href]="signupPriceLink" target="_blank">
          {{ 'SignUpForm.SeeOptionalFeatures' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
