export interface ISignupReferral {
  AdditionalInfoRelevant: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IValidatePasswordRequest {
  Password?: string;
}

export interface IIdentityCheckResponse {
  ExistsInGratisal: boolean;
  BirthDate?: Date;
  IsWellFormed: boolean;
  RaiseChecksumWarning: boolean;
}

export interface ICompany {
  Id: number;
  Name?: string;
  StatusId: number;
  VATRegistrationNumber?: string;
  AccountId?: number;
  Email1?: string;
  Email2?: string;
  DefaultLanguageId: number;
  AddressId: number;
  CompanyTypeId: number;
  SecondaryVATRegistrationNumber?: string;
  MainContactCompanyUserId?: number;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  ExternalSystemComment?: string;
  PaymentMethodSalary: number;
  PaymentMethodPension: number;
  PaymentMethodTax: number;
  ExternalSystemId?: number;
  TelMobile?: string;
  TelFixed?: string;
  KnowsGratisalFrom?: string;
  ExternalAuthentication?: string;
  HasNetsAgreement: boolean;
  Created: Date;
  EmployersAssociationNumber?: string;
  StaticsReportsYearly: boolean;
  StatisticsRecipientId?: number;
  SubscribeToNewsLetter: boolean;
  EstablishedDate?: Date;
  EmployeeCountEstimate?: string;
  Preferences?: string;
  InvoiceIdentifier?: string;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  InvoiceEmail?: string;
  HasAcceptedTerms: boolean;
  CountryId: number;
  CurrentPackageLevel: number;
  ScheduledPackageId?: number;
  ScheduledPackageDate?: Date;
  IndustryCode?: string;
  AccountComment?: string;
  BankId?: number;
  Language?: ILanguage;
  Address?: IAddress;
  CompanyLogo?: ICompanyLogo;
  Departments?: IDepartment[];
  ExternalAccounts?: IExternalAccount[];
  CompanyType?: ICompanyType;
  CompanyStatus?: ICompanyStatus;
  Country?: ICountry;
}

export interface ILanguage {
  Id: number;
  Name?: string;
  Code?: string;
  IsSupported: boolean;
  CultureCode?: string;
}

export interface IAddress {
  Id: number;
  Line1?: string;
  Line2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  Country?: ICountry;
}

export interface ICompanyLogo {
  Id: number;
  Logo?: string;
  Company?: ICompany;
}

export interface IDepartment {
  Id: number;
  CompanyId: number;
  Name?: string;
  Description?: string;
  IsActive: boolean;
  EIncomeReference?: string;
  ExternalReference?: string;
  ManagerCompanyUserId?: number;
}

export interface IExternalAccount {
  Id: number;
  CompanyId: number;
  AccountIdentifier?: string;
  AccountNumber?: string;
  AccountName?: string;
  Description?: string;
  IntegrationId?: number;
  Company?: ICompany;
  Integration?: IIntegration;
}

export interface ICompanyType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICompanyStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICountry {
  IsSupported: boolean;
  DefaultLanguageId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IIntegration {
  Id: number;
  CompanyId: number;
  ExternalSystemId: number;
  Name?: string;
  Created: Date;
  StatusCode: number;
  IsSalaryTypeMappingActive: boolean;
  IntegrationOperations?: IIntegrationOperation[];
  IntegrationConfigurations?: IIntegrationConfiguration[];
  IntegrationCredentials?: IIntegrationCredential[];
}

export interface IIntegrationOperation {
  Id: number;
  IntegrationId: number;
  IsActive: boolean;
  LastRun?: Date;
  StatusCode: number;
  OperationId: number;
  TranslationKey?: string;
  TranslationParametersList?: string;
  Integration?: IIntegration;
  TranslatedStatusMessage?: string;
}

export interface IIntegrationConfiguration {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
}

export interface IIntegrationCredential {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
  ExternalSystemCredential?: IExternalSystemCredential;
  IsPrivate: boolean;
}

export interface IExternalSystemCredential {
  IsPrivate: boolean;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICvrCompany {
  VAT?: string;
  Name?: string;
  Address?: string;
  Zipcode?: string;
  City?: string;
  protected?: boolean;
  Phone?: string;
  Email?: string;
  Fax?: string;
  Startdate?: string;
  Enddate?: string;
  Employees?: string;
  Addressco?: string;
  Industrycode?: number;
  Industrydesc?: string;
  Companycode?: number;
  Companydesc?: string;
  Creditstartdate?: string;
  Creditstatus?: number;
  Creditbankrupt?: boolean;
  Owners?: IApiOwners[];
  Productionunits?: any[];
  T?: number;
  Version?: number;
  ExistsInGratisal: boolean;
}

export interface IApiOwners {
  Name?: string;
}

export class CompanyCreationRequest {
  public VatRegistrationNumber = '';
  public SecondaryVatRegistrationNumber = '';
  public CompanyName = '';
  public ResponsibleUserUsername = '';
  public ResponsibleUserIdentityNumber = '';
  public ResponsibleUserFullName = '';
  public ResponsibleUserEmail = '';
  public PhoneNumber = '';
  public LanguageId = 0;
  public KnowsGratisalFrom = '';
  public NewUserPassword = '';
  public SubscribeToNewsLetter = false;
  public AcceptStartupOffer = false;
  public PackageLevel = 0;
  public AccountId?: number;
  public NewAccountName = '';
  public AccountTypeId = 0;
  public RequestingUserId?: number;
}
