import * as i0 from "@angular/core";
export class ModalService {
    constructor() {
        this.title = '';
        this.message = '';
        this.alertVisible = false;
    }
    alert(title, message) {
        this.title = title;
        this.message = message;
        this.alertVisible = true;
    }
}
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(); }, token: ModalService, providedIn: "root" });
