<header class="Signup-header">
  <div class="container">
    <div class="Signup-logo">
      <a [href]="gratisalLink"><img src="gratisal-logo-horizontal.svg" alt="Gratisal logo" /> </a>
    </div>

    <div class="Signup-headerBack">
      <a [href]="gratisalLink">{{ 'SignUpForm.TILBAGE' | translate }}</a>

      <script type="text/javascript">
        _linkedin_partner_id = '411809';
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      </script>

      <script type="text/javascript">
        (function() {
          var s = document.getElementsByTagName('script')[0];
          var b = document.createElement('script');
          b.type = 'text/javascript';
          b.async = true;
          b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
          s.parentNode.insertBefore(b, s);
        })();
      </script>

      <noscript>
        <img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://dc.ads.linkedin.com/collect/?pid=411809&fmt=gif"
        />
      </noscript>

      <script>
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '539291809823574');
        fbq('track', 'PageView');
      </script>

      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=539291809823574&ev=PageView&noscript=1"
        />
      </noscript>

      <div class="Dropdown-container u-margin-left">
        <button type="button" (click)="languageDropdownOpen = !languageDropdownOpen">
          <div>
            <div class="Avatar Avatar-square">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGBSURBVHjaYvzIwPCPAQqADIG/f/+9evVBUvIfmIuM/oDVAAQQC5DFUV0NVv4PiBgZGZl4eblLiv99/fb/z5//v38zgEkg+9/v3y83bQIIIBawtv//njxl+Pv3PxABwd+/f+8//PflM0jdr9//f//6/+sXUDWTrCzQdIAALM1BDgBADAFA/f+PSahm9+YwuAc4X3ev7cSiHz0ts0EjEVgBOBpzGwAAEAQS99/WDkgU7e95IWi+NuQ7VE03KHz7KiRykwKvAGIE2g90938wgBj//x/QRob/GICRienjhw8AAcTCAJdjAEOwvv/YACPIqH8AAcTyipmZNyvr7/37IFf9+sW1a9f/jx+/+Pr9+/wJ4h6IB4CyLEpKT86dAwggsA2QgAO6FUhCLPv1k+HnT6ggUMMfYOD+BXoV6AeAAAJ5+v/vP0ySkmBj/oICmZkZGIIMX74wQoL/zx+mv2DVf0GyAAHE+BQchZCIBCKxt2//PHr0xtAQLghJB5BoZmJgAAgwAAauWfWiVmegAAAAAElFTkSuQmCC"
                *ngIf="service.language === 'da'"
                title="{{ 'Login.Changelanguage' | translate }}"
                alt="{{ 'Login.Changelanguage' | translate }}"
              />
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                *ngIf="service.language === 'en'"
                title="{{ 'Login.Changelanguage' | translate }}"
                alt="{{ 'Login.Changelanguage' | translate }}"
              />
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAIAAADkharWAAABVUlEQVR4AWP4/v37H6IBUDHD169f/yOBf7///Pv56/+/f/+xAaBihIa/n79+2n301cTFL3vmv1+149ezV/g0/P32/dWkxfdC8u8G5d4Lyr0bmPukpOvnw2c4NXzaf/KWQ9xNm6ibNtEQdMMi4mX/ArjbEBo+Pnzy68mLV5MX33ZNvO2egkCuSY9ym37cfvDr8Yufj58DEZABVMxwPaH8mobX86apv1+9/f3yDQK9ef9h497rhoHXdPygSNv3RmIFw2Wv1AvcRrecEoCKUNz79+/Tit4LPCYX+c2giM8UqJjhokfyBR7jC3ymj/Pbfj1/DVX89fubuWsuyziApHhN4OiiRwrDBbckoA0gxGt80yoKGDjPaife9cu+JGJ1gcsIqAGOgGqAihnOuSedEzA7J2gOQnwmZ3mNgegcrwlMEIGAIkDFDBfmLj9S13O0vpcgAioDKgYAALeZDJKBxD8AAAAASUVORK5CYII="
                *ngIf="service.language === 'kl'"
                title="{{ 'Login.Changelanguage' | translate }}"
                alt="{{ 'Login.Changelanguage' | translate }}"
              />
            </div>
            <svg style="margin-left: 5px" aria-hidden="true" class="Icon Icon--xxsmall">
              <use xlink:href="#chevrondown"></use>
            </svg>
          </div>
        </button>

        <div class="Dropdown Dropdown--top Dropdown-language" [ngClass]="{ 'is-active': languageDropdownOpen }">
          <ul role="menu">
            <li class="Dropdown-item" *ngIf="service.language !== 'da'">
              <button type="button" title="Dansk" (mousedown)="changeLanguageTo('da')">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGBSURBVHjaYvzIwPCPAQqADIG/f/+9evVBUvIfmIuM/oDVAAQQC5DFUV0NVv4PiBgZGZl4eblLiv99/fb/z5//v38zgEkg+9/v3y83bQIIIBawtv//njxl+Pv3PxABwd+/f+8//PflM0jdr9//f//6/+sXUDWTrCzQdIAALM1BDgBADAFA/f+PSahm9+YwuAc4X3ev7cSiHz0ts0EjEVgBOBpzGwAAEAQS99/WDkgU7e95IWi+NuQ7VE03KHz7KiRykwKvAGIE2g90938wgBj//x/QRob/GICRienjhw8AAcTCAJdjAEOwvv/YACPIqH8AAcTyipmZNyvr7/37IFf9+sW1a9f/jx+/+Pr9+/wJ4h6IB4CyLEpKT86dAwggsA2QgAO6FUhCLPv1k+HnT6ggUMMfYOD+BXoV6AeAAAJ5+v/vP0ySkmBj/oICmZkZGIIMX74wQoL/zx+mv2DVf0GyAAHE+BQchZCIBCKxt2//PHr0xtAQLghJB5BoZmJgAAgwAAauWfWiVmegAAAAAElFTkSuQmCC"
                  title="Dansk"
                  alt="Dansk"
                />
              </button>
            </li>
            <li class="Dropdown-item" *ngIf="service.language !== 'en'">
              <button type="button" title="English" (mousedown)="changeLanguageTo('en')">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                  title="English"
                  alt="English"
                />
              </button>
            </li>
            <li class="Dropdown-item" *ngIf="service.language !== 'kl'">
              <button type="button" title="Greenlandic" (mousedown)="changeLanguageTo('kl')">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAIAAADkharWAAABVUlEQVR4AWP4/v37H6IBUDHD169f/yOBf7///Pv56/+/f/+xAaBihIa/n79+2n301cTFL3vmv1+149ezV/g0/P32/dWkxfdC8u8G5d4Lyr0bmPukpOvnw2c4NXzaf/KWQ9xNm6ibNtEQdMMi4mX/ArjbEBo+Pnzy68mLV5MX33ZNvO2egkCuSY9ym37cfvDr8Yufj58DEZABVMxwPaH8mobX86apv1+9/f3yDQK9ef9h497rhoHXdPygSNv3RmIFw2Wv1AvcRrecEoCKUNz79+/Tit4LPCYX+c2giM8UqJjhokfyBR7jC3ymj/Pbfj1/DVX89fubuWsuyziApHhN4OiiRwrDBbckoA0gxGt80yoKGDjPaife9cu+JGJ1gcsIqAGOgGqAihnOuSedEzA7J2gOQnwmZ3mNgegcrwlMEIGAIkDFDBfmLj9S13O0vpcgAioDKgYAALeZDJKBxD8AAAAASUVORK5CYII="
                  title="Greenlandic"
                  alt="Greenlandic"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
