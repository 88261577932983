import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { SignupService } from '../signup.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  public languageDropdownOpen = false;

  public get gratisalLink(): string {
    if (this.configService.config.hideFreeFeatures) {
      return 'http://www.gratisal.gl/';
    }

    return 'http://gratisal.dk/' + this.service.language;
  }

  constructor(public configService: AppConfigService, public service: SignupService, private router: Router) {}

  public changeLanguageTo(language: string) {
    this.languageDropdownOpen = false;
    this.router.navigate([language]);
  }
}
