import { HttpClient } from '@angular/common/http';
import * as Raven from 'raven-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AppConfigService {
    constructor(http) {
        this.http = http;
    }
    loadAppConfig() {
        return this.http
            .get('./config.json')
            .toPromise()
            .then((data) => {
            this.appConfig = data;
            Raven.config(this.appConfig.sentryDSN).install();
        });
    }
    get config() {
        return this.appConfig;
    }
}
AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.ɵɵinject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
