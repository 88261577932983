import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html'
})
export class FeaturesComponent {
  @Input() public language = 'en';

  public get signupPriceLink(): string {
    switch (this.language) {
      case 'da':
        return 'http://www.gratisal.dk/da/priser/';
      case 'en':
        return 'http://www.gratisal.dk/en/prices/';
      case 'kl':
        return 'http://www.gratisal.dk/kl/priser/';
      default:
        return 'http://www.gratisal.dk/da/priser/';
    }
  }
}
