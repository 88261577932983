import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig!: AppConfig;

  constructor(private http: HttpClient) {}

  public loadAppConfig() {
    return this.http
      .get('./config.json')
      .toPromise()
      .then((data: any) => {
        this.appConfig = data;
        Raven.config(this.appConfig.sentryDSN).install();
      });
  }

  public get config() {
    return this.appConfig;
  }
}
