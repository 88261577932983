import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
import * as i3 from "@ngx-translate/core";
export class SignupService {
    constructor(httpClient, configService, translate) {
        this.httpClient = httpClient;
        this.configService = configService;
        this.translate = translate;
        this.languageValue = '';
        this.knowGratisalFromValue = '';
    }
    get signupReferrals() {
        if (!this.signupReferralsSubject) {
            this.signupReferralsSubject = new BehaviorSubject([]);
            this.getSignupReferrals().subscribe((data) => this.signupReferralsSubject.next(data));
        }
        return this.signupReferralsSubject.asObservable();
    }
    get language() {
        return this.languageValue;
    }
    set language(value) {
        if (this.languageValue !== value) {
            this.languageValue = value;
            this.translate.use(value).subscribe(() => { });
        }
    }
    get knowGratisalFrom() {
        return this.knowGratisalFromValue;
    }
    set knowGratisalFrom(value) {
        this.knowGratisalFromValue = value;
        this.selectedReferral = this.signupReferralsSubject.value.find((e) => e.Key === value);
    }
    validatePassword(password) {
        const path = this.configService.config.apiUrl + '/api/auth/validatepw';
        return this.httpClient.post(path, { password });
    }
    /**
     * Checks that a given identity (CPR) number is well-formed, and whether it represents an existing Gratisal user.
     * Also gives the associated date of birth, and whether a warning should be raised for a failed "modulo 11 check".
     */
    checkIdentityNumber(identityNumber) {
        let path = this.configService.config.apiUrl + '/api/users/identity/{identityNumber}';
        path = path.replace('{identityNumber}', identityNumber.toString());
        return this.httpClient.get(path);
    }
    /**
     * Submits a request to sign up as a new customer - creating an account, a company and a user. This method may be
     * called anonymously.
     * @param An object containing information about the company to create. Note that AccountId and RequestingUserId
     *        must always be null when using this route. To create a new company when logged on, call POST to
     *        api/account/companies instead.
     */
    signup(request) {
        const path = this.configService.config.apiUrl + '/api/companies/signup';
        return this.httpClient.post(path, request);
    }
    /**
     * Gets information about a company by CVR number. Powered by www.cvrapi.dk.
     */
    getCompanyByCvr(cvrNumber) {
        let path = this.configService.config.apiUrl + '/api/companies/cvr/{cvrNumber}';
        path = path.replace('{cvrNumber}', cvrNumber.toString());
        return this.httpClient.get(path);
    }
    getSignupReferrals() {
        const path = this.configService.config.apiUrl + '/api/staticdata/signupreferral';
        return this.httpClient.get(path);
    }
}
SignupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignupService_Factory() { return new SignupService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.TranslateService)); }, token: SignupService, providedIn: "root" });
