import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalService } from './modal.service';
import * as i0 from "@angular/core";
import * as i1 from "./modal.service";
import * as i2 from "@ngx-translate/core";
export class ErrorInterceptor {
    constructor(modalService, translateService) {
        this.modalService = modalService;
        this.translateService = translateService;
    }
    intercept(req, next) {
        return next.handle(req).pipe(catchError((err, caught) => {
            if (err instanceof HttpErrorResponse) {
                let message = err.statusText;
                if (err.url) {
                    message += ' - ' + err.url;
                }
                switch (err.status) {
                    case 0:
                    case -1:
                        this.modalService.alert('', 'Error.NetworkError');
                        this.translateService.use('da').subscribe(() => { });
                        break;
                    case 400:
                        break;
                    case 401:
                        break;
                    case 404:
                        break;
                    default:
                        Raven.captureException(new Error(message), { extra: err });
                        break;
                }
            }
            return throwError(err);
        }));
    }
}
ErrorInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorInterceptor_Factory() { return new ErrorInterceptor(i0.ɵɵinject(i1.ModalService), i0.ɵɵinject(i2.TranslateService)); }, token: ErrorInterceptor, providedIn: "root" });
